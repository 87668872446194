export const FETCH_GRID_SUCCESS = "FETCH_GRID_SUCCESS";

const fetchGridSuccess = grids => ({
    type: FETCH_GRID_SUCCESS,
    payload: grids
});

// fetch products
export const fetchGrid = grids => {
    return dispatch => {
        dispatch(fetchGridSuccess(grids));
    };
};
