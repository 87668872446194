export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const setLogin = ( loggedInUser ) => {
    return dispatch => {
        dispatch ({
            type: LOGIN,
            payload: {
                session:    loggedInUser['session'],
                firstName:  loggedInUser['user'],
                account:    loggedInUser['session_account']
            }
        });
    }

};

export const setLogout = () => {
    return dispatch => {
        dispatch({
            type: LOGOUT,
            payload: {
                logout: true
            }
        });
    }
};