import { FETCH_GRID_SUCCESS } from "../actions/gridActions";

const initState = {
    grids: []
};

const gridReducer = (state = initState, action) => {
    if (action.type === FETCH_GRID_SUCCESS) {
        return {
            ...state,
            grids: action.payload
        };
    }

    return state;
};

export default gridReducer;