import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";

const HomeGridBanner    = lazy(() => import("./pages/home/HomeGridBanner"));

// shop pages
const ShopGridStandard  = lazy(() => import("./pages/shop/ShopGridStandard"));
const Product           = lazy(() => import("./pages/shop-product/Product"));

// other pages
const About             = lazy(() => import("./pages/other/About"));
const AgriCommunity     = lazy(() => import("./pages/other/AgriCommunity"));
const CorporateBranding = lazy(() => import("./pages/other/CorporateBranding"));
const Hygiene           = lazy(() => import("./pages/other/Hygiene"));
const EarthLinen        = lazy(() => import("./pages/other/EarthLinen"));

const Contact           = lazy(() => import("./pages/other/Contact"));
const MyAccount         = lazy(() => import("./pages/other/MyAccount"));
const AddressBook       = lazy(() => import("./pages/other/AddressBook"));
const OrderHistory      = lazy(() => import("./pages/other/OrderHistory"));

// authentication
const Login             = lazy(() => import("./pages/auth/Login"));
const Register          = lazy(() => import("./pages/auth/Register"));
const ForgotPassword    = lazy(() => import("./pages/auth/ForgotPassword"));
const UpdatePassword    = lazy(() => import("./pages/auth/UpdatePassword"));
const ActivateAccount   = lazy(() => import("./pages/auth/ActivateAccount"));

const Cart              = lazy(() => import("./pages/other/Cart"));
const Wishlist          = lazy(() => import("./pages/other/Wishlist"));
const Compare           = lazy(() => import("./pages/other/Compare"));
const Checkout          = lazy(() => import("./pages/other/Checkout"));

const NotFound          = lazy(() => import("./pages/other/NotFound"));

const App = props => {
    useEffect(() => {
        props.dispatch(
            loadLanguages({
                languages: {
                    en: require("./translations/english.json"),
                    fn: require("./translations/french.json"),
                    de: require("./translations/germany.json")
                }
            })
        );
    });

    return (
        <ToastProvider placement="bottom-left">
            <BreadcrumbsProvider>
                <Router>
                    <ScrollToTop>
                        <Suspense
                            fallback={
                                <div className="flone-preloader-wrapper">
                                    <div className="flone-preloader">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            }
                        >
                            <Switch>
                                <Route
                                    exact
                                    path={process.env.PUBLIC_URL + "/"}
                                    component={HomeGridBanner}
                                />

                                {/* Shop pages */}
                                <Route
                                    path={process.env.PUBLIC_URL + "/shop"}
                                    component={ShopGridStandard}
                                />

                                <Route
                                    path={process.env.PUBLIC_URL + "/product/:id"}
                                    render={routeProps => (
                                        <Product {...routeProps} key={routeProps.match.params.id} />
                                    )}
                                />

                                {/* Service pages */}
                                <Route
                                    path={process.env.PUBLIC_URL + "/agriculture-and-community"}
                                    component={AgriCommunity}
                                />
                                <Route
                                    path={process.env.PUBLIC_URL + "/corporate-branding"}
                                    component={CorporateBranding}
                                />
                                <Route
                                    path={process.env.PUBLIC_URL + "/hygiene"}
                                    component={Hygiene}
                                />
                                <Route
                                    path={process.env.PUBLIC_URL + "/linen-and-interiors"}
                                    component={EarthLinen}
                                />

                                {/* Other pages */}
                                <Route
                                    path={process.env.PUBLIC_URL + "/about"}
                                    component={About}
                                />
                                <Route
                                    path={process.env.PUBLIC_URL + "/contact"}
                                    component={Contact}
                                />
                                <Route
                                    path={process.env.PUBLIC_URL + "/my-account"}
                                    component={MyAccount}
                                />
                                <Route
                                    path={process.env.PUBLIC_URL + "/address-book/:id"}
                                    render={routeProps => (
                                        <AddressBook {...routeProps} key={routeProps.match.params.id} />
                                    )}
                                />
                                <Route
                                    path={process.env.PUBLIC_URL + "/address-book"}
                                    component={AddressBook}
                                />
                                <Route
                                    path={process.env.PUBLIC_URL + "/order-history"}
                                    component={OrderHistory}
                                />
                                {/* Authentication page links */}
                                <Route
                                    path={process.env.PUBLIC_URL + "/register"}
                                    component={Register}
                                />
                                <Route
                                    path={process.env.PUBLIC_URL + "/login"}
                                    component={Login}
                                />
                                <Route
                                    path={process.env.PUBLIC_URL + "/forgot-password"}
                                    component={ForgotPassword}
                                />
                                <Route
                                    path={process.env.PUBLIC_URL + "/update-password"}
                                    component={UpdatePassword}
                                />
                                <Route
                                    path={process.env.PUBLIC_URL + "/activate-account"}
                                    component={ActivateAccount}
                                />

                                <Route
                                    path={process.env.PUBLIC_URL + "/cart"}
                                    component={Cart}
                                />
                                <Route
                                    path={process.env.PUBLIC_URL + "/wishlist"}
                                    component={Wishlist}
                                />
                                <Route
                                    path={process.env.PUBLIC_URL + "/compare"}
                                    component={Compare}
                                />
                                <Route
                                    path={process.env.PUBLIC_URL + "/checkout"}
                                    component={Checkout}
                                />

                                <Route
                                    path={process.env.PUBLIC_URL + "/not-found"}
                                    component={NotFound}
                                />

                                <Route exact component={NotFound} />
                            </Switch>
                        </Suspense>
                    </ScrollToTop>
                </Router>
            </BreadcrumbsProvider>
        </ToastProvider>
    );
};

App.propTypes = {
    dispatch: PropTypes.func
};

export default connect()(multilanguage(App));
