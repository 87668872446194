import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import { fetchProducts } from "./redux/actions/productActions";
import { fetchGrid } from "./redux/actions/gridActions";
import rootReducer from "./redux/reducers/rootReducer";
import { apiURL } from "./helpers/xhr-functions";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";
import { composeWithDevTools } from "redux-devtools-extension";
import axios from "axios";

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save()))
);

// fetch products
axios(
    {
        method:     'GET',
        url:        apiURL + 'product/store-products/',
        headers:    {
            'Content-Type': 'application/json'
        },
        data:   {}
    }
)
    .then(response => {
        const json_response = response.data;
        if(json_response['records'] && json_response['records'].length > 0){
            const storeProducts = Object.values(json_response['records']);
            store.dispatch(fetchProducts(storeProducts));
        }
    })
    .catch(err => {
        console.log("Error: ", err);
        store.dispatch(fetchProducts([]));
    });

// fetch grid content
axios(
    {
        method:     'GET',
        url:        apiURL + 'homegrid/get-grid/',
        headers:    {
            'Content-Type': 'application/json'
        },
        data:   {}
    }
)
    .then(response => {
        const json_response = response.data;
        if(json_response['records'] && json_response['records'].length > 0){
            const gridContent = Object.values(json_response['records']);
            store.dispatch(fetchGrid(gridContent));
        }
    })
    .catch(err => {
        console.log("Error: ", err);
        store.dispatch(fetchGrid([]));
    });

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
