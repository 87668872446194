import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import gridReducer from "./gridReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import loginReducer from "./loginReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";

const rootReducer = combineReducers(
    {
        multilanguage:  createMultilanguageReducer({ currentLanguageCode: "en" }),
        currencyData:   currencyReducer,
        productData:    productReducer,
        gridData:       gridReducer,
        cartData:       cartReducer,
        wishlistData:   wishlistReducer,
        compareData:    compareReducer,
        loginData:      loginReducer
    }
);

export default rootReducer;
