import { LOGIN, LOGOUT } from "../actions/loginActions";

const initState = {};

const loginReducer = (state = initState, action) => {
    if (action.type === LOGIN) {
        const sessionDetails = action.payload;
        if(sessionDetails) {
            return {
                ...state,
                session:    sessionDetails.session,
                firstName:  sessionDetails.firstName,
                account:    sessionDetails.account
            };
        }
    }

    if (action.type === LOGOUT) {
        const sessionDetails = action.payload;
        if(sessionDetails.logout) {
            return {};
        }
    }

    return state;
};

export default loginReducer;