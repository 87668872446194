let actionButtonContent = '';
const txtSubmitLoad 	= '<img src="/assets/img/loader.png" alt="processing..." />';
export const apiURL     = '//sbdevelopments.co.za/php/';
export const adminURL   = '//sbdevelopments.co.za/admin/index.php';

/*
*   Form processing progress
*/
export const showXHRProgress = (xProgressContainer) =>
{
    actionButtonContent	= xProgressContainer.innerHTML;
    xProgressContainer.innerHTML = txtSubmitLoad;
    xProgressContainer.setAttribute('disabled', true);
};

export const hideXHRProgress = (xProgressContainer) =>
{
    xProgressContainer.innerHTML = actionButtonContent;
    xProgressContainer.removeAttribute('disabled');
    actionButtonContent = '';
};

/*
*   Form validation constants
*/
export const validateEmail = (emailAddress) => {
    let regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(String(emailAddress).toLowerCase());
};

export const validateTel = (telephoneNum) => {
    let regExp = /^[+]?(1-|1\s|1|\d{3}-|\d{3}\s|)?((\(\d{3}\))|\d{3})(-|\s)?(\d{3})(-|\s)?(\d{4})$/g;
    return regExp.test(String(telephoneNum).toLowerCase());
};

export const validatePassword = (password) => {
    let regExp = /^(?=.*\d)(?=.*[A-Za-z])[0-9A-Za-z!@#$%]{8,12}$/g;
    return regExp.test(String(password).toLowerCase());
};

const insertAfter = (referenceNode, newNode) => {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
};

export const showInputError = (referenceNode, errorElemId, errorMessage) => {
    removeInputError(errorElemId);
    let errorElement = document.createElement('span');
    errorElement.innerHTML      = errorMessage;
    errorElement.id             = errorElemId;
    errorElement.style.cssText  = 'color:#e74c3c;font-size:12px';
    insertAfter(referenceNode, errorElement);
};

export const removeInputError = (errorElemID) => {
    if(document.getElementById(errorElemID)){
        document.getElementById(errorElemID).remove();
    }
};

/*
*   Form validation function
*/

export const validateForm = (formValues) => {
    let formValid = 1;
    for(const index of Object.keys(formValues)){
        let htmlElem = document.getElementById(index);
        if(htmlElem){
            let htmlElem = document.getElementById(index);
            if(htmlElem){
                if(formValues[index].length < 1 && htmlElem.hasAttribute('required')) {
                    htmlElem.classList.add('error');
                    showInputError(htmlElem, index + 'Error', 'This field is required.');
                    formValid = 0;
                }
                else{
                    htmlElem.classList.remove('error');
                    removeInputError(index + 'Error');
                }

                if(formValues[index].length > 0 && htmlElem.type.toLowerCase() === 'email' && !validateEmail(formValues[index])) {
                    htmlElem.classList.add('error');
                    showInputError(htmlElem, index + 'Error', 'Invalid email address.');
                    formValid = 0;
                }

                if(formValues[index].length > 0 && htmlElem.type.toLowerCase() === 'tel' && !validateTel(formValues[index])) {
                    htmlElem.classList.add('error');
                    showInputError(htmlElem, index + 'Error', 'Invalid phone number.');
                    formValid = 0;
                }

                if(formValues[index].length > 0 && htmlElem.type.toLowerCase() === 'password') {
                    if(formValues[index].length < 8){
                        htmlElem.classList.add('error');
                        showInputError(htmlElem, index + 'Error', 'Password should contain a minimum of 8 characters.');
                        formValid = 0;
                    }
                    if(!validatePassword(formValues[index])){
                        htmlElem.classList.add('error');
                        showInputError(htmlElem, index + 'Error', 'Your password should contain a Capital letter, symbol(#$@_%) and a number.');
                        formValid = 0;
                    }
                }
            }
        }
    }

    return formValid;
};